import AuthorisationGuard from "@/router/guards/AuthorisationGuard.js";

export default {
  path: "",
  beforeEnter: AuthorisationGuard,
  component: () => import("@/templates/Default"),
  children: [
    {
      path: "",
      name: "home",
      redirect: { name: "client.index" },
    },
    {
      path: "/opdrachtgevers",
      name: "client.index",
      component: () => import("@/views/ClientResource.vue"),
    },
    {
      path: "/koppelingen",
      name: "connector.index",
      component: () => import("@/views/MicroserviceResource.vue"),
    },
    {
      path: "/koppelingen/:microserviceId",
      component: () => import("@/views/microservice/LayoutWrapper.vue"),
      children: [
        {
          path: "",
          name: "connector.microservice",
          component: () => import("@/views/microservice/ConnectorResource.vue"),
        },
      ],
    },
    {
      path: "/api-tokens",
      name: "token.index",
      component: () => import("@/views/TokenResource.vue"),
    },
    {
      path: "/api-credentials",
      name: "apiCredentials.index",
      component: () => import("@/views/ApiCredentialsResource.vue"),
    },
    {
      path: "/ordertoevoegingen",
      name: "instruction.orderAdditions",
      component: () => import("@/views/InstructionResource.vue"),
    },
    {
      path: "/complimentcards",
      name: "instruction.complimentCards",
      component: () => import("@/views/ComplimentCardResource.vue"),
    },
    {
      path: "/opdrachtgever/:clientId/",
      component: () => import("@/views/client/LayoutWrapper.vue"),
      children: [
        {
          path: "product/",
          component: () => import("@/views/product/Layout.vue"),
          children: [
            {
              path: ":productId",
              name: "product.actions",
              component: () =>
                import("@/views/product/ProductProductActionResource.vue"),
            },
          ],
        },
        {
          path: "",
          component: () => import("@/views/client/Layout.vue"),
          children: [
            {
              path: "",
              name: "client.connectors",
              component: () => import("@/views/client/ConnectorResource.vue"),
            },
            {
              path: "producten",
              name: "client.products",
              component: () => import("@/views/client/ProductResource.vue"),
            },
            {
              path: "anonimiseren",
              name: "client.anonymize",
              component: () => import("@/views/client/ClientAnonymize.vue"),
            },
            {
              path: "landen",
              name: "client.countries",
              component: () => import("@/views/client/ClientCountries.vue"),
            },
            {
              path: "webhooks",
              name: "client.webhook",
              component: () => import("@/views/client/WebhookResource.vue"),
            },
            {
              path: "webhook-log",
              name: "client.webhook-log",
              component: () => import("@/views/client/WebhookLogResource.vue"),
            },
            {
              path: "particularities",
              name: "client.particularities",
              component: () =>
                import("@/views/client/ClientParticularities.vue"),
            },
          ],
        },
      ],
    },
  ],
};
